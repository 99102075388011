// 1. File Paths
// Usage - e.g. url('#{$image-directory}...')
// --------------
$public-path: '../';
$image-directory: '../images/';
$font-directory: '../fonts/';

// 2. Width
// --------------
$global-width: 1440px;

// 3. Fonts
// --------------
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
$primary-font: 'Outfit', sans-serif;
//$secondary-font: "Work Sans", sans-serif;

// 4. Colours
// --------------
/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
$primary-colour: #ffad33;
$secondary-colour: #81ad1c;
$tertiary-colour: #3399ff;
$quaternary-colour: #757471;
/// @type Map
$foundation-palette: (
  primary: $primary-colour,
  secondary: $secondary-colour,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;
$light-grey: #e6e6e6 !default;
$medium-grey: #d3d3d3 !default;
$dark-grey: #9e9e9e !default;
$black: #0a0a0a !default;
$white: #ffffff !default;
$off-white: #fbfbfb !default;

$primary-colour-light: lighten($primary-colour, 4%);
$primary-colour-dark: darken($primary-colour, 4%);
$secondary-colour-light: lighten($secondary-colour, 4%);
$secondary-colour-dark: darken($secondary-colour, 4%);
$tertiary-colour-light: lighten($tertiary-colour, 4%);
$tertiary-colour-dark: darken($tertiary-colour, 4%);
$quaternary-colour-light: lighten($quaternary-colour, 4%);
$quaternary-colour-dark: darken($quaternary-colour, 4%);

// 5. Base
// -----------
$base-font-family: $primary-font !default;
$base-font-size: 100% !default;
$base-line-height: 1.5 !default;
$base-font-color: $quaternary-colour !default;
$base-font-weight: 400 !default;
$base-background-color: $white !default;

// 6. Headings
// --------------
$base-heading-font-family: $primary-font !default;
$base-heading-line-height: 1.2 !default;
$base-heading-font-weight: 700 !default;
$base-heading-text-transform: none !default;
$base-heading-color: $tertiary-colour !default;

$base-heading-large: 2.8rem !default;

$base-h1-font-size: 2.2rem !default;
$base-h2-font-size: 2rem !default;
$base-h3-font-size: 1.8rem !default;
$base-h4-font-size: 1.4rem !default;
$base-h5-font-size: 1.2rem !default;
$base-h6-font-size: 1.1rem !default;

$tablet-heading-reduction: 0.2rem;

$base-h1-font-size-tablet: $base-h1-font-size - $tablet-heading-reduction !default;
$base-h2-font-size-tablet: $base-h2-font-size - $tablet-heading-reduction !default;
$base-h3-font-size-tablet: $base-h3-font-size - $tablet-heading-reduction !default;
$base-h4-font-size-tablet: $base-h4-font-size - $tablet-heading-reduction !default;
$base-h5-font-size-tablet: $base-h5-font-size - $tablet-heading-reduction !default;
$base-h6-font-size-tablet: $base-h6-font-size - $tablet-heading-reduction !default;

$mobile-heading-reduction: 0.4rem;

$base-h1-font-size-mobile: $base-h1-font-size - $mobile-heading-reduction !default;
$base-h2-font-size-mobile: $base-h2-font-size - $mobile-heading-reduction !default;
$base-h3-font-size-mobile: $base-h3-font-size - $mobile-heading-reduction !default;
$base-h4-font-size-mobile: $base-h4-font-size - $mobile-heading-reduction !default;
$base-h5-font-size-mobile: $base-h5-font-size - $mobile-heading-reduction !default;
$base-h6-font-size-mobile: $base-h6-font-size - $mobile-heading-reduction !default;

// 7. Breakpoints
// --------------
//  Mixin scss classes e.g. @include bp(mlarge)
//  & Foundation breakpoint html class names e.g. class="small-6 xlarge-12"
/// @type Map
$breakpoints: (
  small: 0,
  smedium: 480px,
  medium: 576px,
  mlarge: 768px,
  large: 960px,
  xlarge: 1024px,
  xxlarge: 1280px,
  xxxlarge: $global-width,
  xxxxlarge: 1800px,
);

/// @type List
$breakpoint-classes: (
  small smedium medium mlarge large xlarge xxlarge xxxlarge xxxxlarge
);

// 8. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;

$grid-gutter: 16px !default;
$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;

// 9. Global
// --------------
$global-lineheight: 1.5 !default;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 10. Transition
// --------------
$global-transition: 500ms ease-in-out;
