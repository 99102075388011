/* Banner / Slider
================================================== */
#banner {
  padding: 0;
  .n2-ss-slide-limiter {
    max-width: 100% !important;
  }
  .slider-button {
    &,
    a {
      transition: $global-transition !important;
    }
  }
  * {
    font-family: $primary-font !important;
  }
  h1 {
    @include bp_max(mlarge) {
      font-size: 2rem !important;
    }
  }
  .subheading {
    span {
      @include bp_max(medium) {
        display: block;
      }
    }
    &__divider {
      @include bp_max(medium) {
        display: none !important;
      }
    }
  }
}
.banner {
  &--main {
    .n2-ss-slide-background-image {
      mix-blend-mode: exclusion;
      opacity: 0.8;
    }
    .n2-ss-slide-background-color {
      z-index: 7 !important;
      mix-blend-mode: color;
      opacity: 0.2;
      background-color: $tertiary-colour-dark !important;
    }
    .n2-ss-slide-background {
      background-color: $black;
    }
    .n2-ss-button-container {
      a {
        padding: 1rem 1.5rem !important;
        border-radius: 0 !important;
        font-family: $primary-font !important;
        font-weight: 600 !important;
        letter-spacing: 1px !important;
      }
    }
    .button--has-icon-right {
      .n2-ss-button-container {
        a {
          padding-right: 1rem !important;
        }
      }
    }
    .button--has-icon-left {
      .n2-ss-button-container {
        a {
          padding-left: 1rem !important;
        }
      }
    }
  }
  &__overlay,
  &__video-bg {
    height: 100% !important;
  }
  &__overlay {
    mix-blend-mode: color;
  }
  &__video-bg {
    opacity: 0.8;
    .n2_ss_video_player {
      position: relative;
      overflow: hidden;
      height: 100%;
      iframe {
        box-sizing: border-box;
        height: 65vw !important;
        left: 50% !important;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50% !important;
        width: 180vh !important;
      }
    }
  }
  &--no-overlay {
    .n2-ss-slide-background-color {
      display: none;
    }
  }
}
