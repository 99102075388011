/* Header
================================================== */
header {
  position: absolute;
  top: 0;
  z-index: 90;
  width: 100%;
  background-color: $white;
  box-shadow: 0 0 10px rgba(44, 44, 44, 0.2);
  transition: $global-transition;
  @include bp(medium) {
    position: fixed;
  }
  .row {
    max-width: 100%;
    align-items: center;
  }
  .shrink & {
    @include bp_max(medium) {
      position: fixed;
    }
    .header__logo {
      img {
        @include bp(medium) {
          width: 160px;
        }
      }
    }
    .header__wrap {
      @include bp(medium) {
        padding: 0.5rem 0;
      }
    }
  }
  .event-details {
    margin-right: auto;
    @include bp(large) {
      margin-right: 0;
      margin-left: 1rem;
    }
    p {
      margin: 0;
      font-size: 0.9rem;
    }
    &__location {
      font-weight: bold;
    }
  }

  // If has multiple events, dont shrink
  .multiple-events & {
    position: absolute !important;
  }
}
.header {
  &__wrap {
    padding: 1rem 0;
    transition: $global-transition;
  }
  &__logo {
    flex: none;
    position: relative;
    z-index: 20;
    @include bp(xxlarge) {
      margin-right: 2rem;
    }
    a {
      display: flex;
      flex-direction: column;
      @include bp(large) {
        flex-direction: row;
        align-items: center;
      }
      &:hover {
        color: $secondary-colour;
      }
    }
    p {
      margin: 0;
    }
    img {
      display: block;
      width: 160px;
      margin-top: -4px;
      transition: $global-transition;
      /*@include bp(smedium) {
        width: 200px;
      }
      @include bp(xlarge) {
        width: 180px;
      }
      @include bp(xxlarge) {
        width: 200px;
      }*/
    }
  }
  /*&--general,
  .multiple-events &--general {
    @include bp(medium) {
      position: fixed !important;
    }
    .shrink {
      @include bp_max(medium) {
        position: fixed;
      }
    }
  }*/
}
