@import '_mixins';

.none {
  display: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}

.hide-smedium {
  @include bp(smedium) {
    display: none !important;
  }
}
.hide-medium {
  @include bp(medium) {
    display: none !important;
  }
}
.hide-mlarge {
  @include bp(mlarge) {
    display: none !important;
  }
}
.hide-large {
  @include bp(large) {
    display: none !important;
  }
}
.hide-xlarge {
  @include bp(xlarge) {
    display: none !important;
  }
}
.hide-xxlarge {
  @include bp(xxlarge) {
    display: none !important;
  }
}
.hide-xxxlarge {
  @include bp(xxxlarge) {
    display: none !important;
  }
}
