/* Sponsors Section
================================================== */
#sponsors-section {
  h6 {
    @include bp_max(medium) {
      text-align: center;
    }
  }
}

/* Footer
================================================== */
footer {
  * {
    color: $white;
  }
  .moduletable {
    margin: 1rem 0;
    @include bp(medium) {
      margin: 0;
    }
  }
  .nav {
    .mod-menu__sub {
      display: none !important;
    }
    li {
      margin: 0.5rem 0;
      text-align: left;
    }
    a {
      display: inline-flex;
      justify-content: flex-start;
      padding: 0;
      font-size: 0.9rem;
      color: $white;
      &:hover {
        color: $primary-colour;
      }
    }
  }
  .event-location {
    ul {
      padding: 0;
      list-style: none;
    }
    li {
      margin: 0;
      padding: 0 0 0.25rem 0;
    }
  }
  .organiser-logo {
    img {
      //max-width: 150px;
    }
  }
}

.footer {
  &__top {
    padding: 5rem 0;
    //background-color: lighten($black, 8%);
    background-color: darken($secondary-colour, 4%);
  }
  &__column-1 {
    img,
    svg {
      width: 100%;
      max-width: 280px;

      &.headline {
        max-width: 100px;
      }
    }
  }
  &__logo {
    display: block;
    margin-bottom: 3rem;
  }
  &__social {
    margin-top: 2rem;
    svg {
      * {
        fill: $white;
      }
    }
    a {
      &:hover {
        svg {
          * {
            fill: $primary-colour;
          }
        }
      }
    }
  }
  &__bottom {
    padding: 0.5rem 0;
    background-color: darken($secondary-colour, 8%);
    .row {
      align-items: center;
    }
    .nav {
      padding: 0;
      li {
        display: block;
        text-align: center;
        @include bp(medium) {
          display: inline-flex;
          text-align: center;
          margin: 0 2rem 0 0;
        }
      }
      a {
        text-transform: uppercase;
        font-size: 0.8rem;
      }
    }
  }
  &__signature {
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    @include bp(medium) {
      justify-content: flex-end;
    }
    a {
      display: flex;
      align-items: center;
    }
    img {
      margin-left: 1rem;
      width: 12rem;
      @include transition($global-transition);
    }
  }
}
