/* Login
================================================== */
.com-users-login,
.com-users-logout,
.login-module,
.com-users-reset,
.com-users-remind,
.reset-confirm {
  width: 100%;
  max-width: 720px;
  margin: 6rem auto;
  padding: 0 0.9375rem;
  form {
    padding: 2rem;
    background-color: $off-white;
    border-bottom: 4px solid $primary-colour;
    legend {
      margin-bottom: 2rem;
    }
    fieldset {
      margin: 0;
      padding: 0;
      border: 0;
    }
    label {
      display: block;
    }
    input {
      padding: 12px 16px;
      line-height: 28px;
      font-size: 13px;
      font-weight: 700;
      border: none;
      border-radius: none;
      font-family: $primary-font;
      color: $black;
    }
    .btn {
      margin: 0;
    }
  }
  .control-group,
  .form-group {
    margin-bottom: 1rem;
  }
  .com-users-login__remember {
    margin-bottom: 1rem;
  }
  .mod-login__options {
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      margin-right: 2rem;
    }
    a {
      color: $secondary-colour;
      &:hover {
        color: $primary_colour;
      }
    }
  }
  .list-group-item {
    margin-right: 2rem;
  }
  .password-group {
    .input-group {
      display: flex;
    }
  }
}
.mod-login__remember {
  .form-check {
    display: flex;
  }
  .form-check-input {
    margin-right: 0.5rem;
  }
}
.input-password-toggle {
  margin: 0;
  padding: 0.8rem;
  font-size: 0.7rem;
}
button[class*='plg_system_webauthn_login_button'] {
  padding: 1rem 1.5rem;
  svg {
    width: 20px;
    margin-right: 0.5rem;
    * {
      fill: $white;
    }
  }
}
.com-users-logout {
  text-align: center;
  .com-users-logout__submit {
    margin: 0;
  }
}

.mod-login__options,
.plg_system_webauthn_login_button,
.mod-login__remember,
.com-users-login__options {
  display: none;
}
